import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const NotFoundPage = () => (
  <HelmetProvider>
    <Helmet>
      <title>Page not Found</title>
      <meta
        name="description"
        content="The page you were looking for does not exist."
      />
    </Helmet>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </HelmetProvider>
);

export default NotFoundPage;
